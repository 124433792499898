.assignment-hub {
  position: relative;

  &__create-assignment {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 1rem;
    border: 1px solid black;
    border-radius: 4px;
    width: 100px;
    padding: 8px 12px;
    transition: 0.2s;
    cursor: pointer;
    font-family: "Fellix-Bold";
    background-color: white;

    &:hover {
      background: black;
      color: white;
    }
  }
  &__close-assignment-creation {
    position: absolute;
    background-color: white;
    top: 3px;
    right: 5%;
    font-size: 1rem;
    border: 1px solid black;
    border-radius: 4px;
    width: 100px;
    padding: 8px 12px;
    transition: 0.2s;
    font-family: "Fellix-SemiBold";
    cursor: pointer;
    &:hover {
      background: black;
      color: white;
    }
  }
  &__tab {
    padding: 8px 12px;

    cursor: pointer;
    &--selected {
      position: relative;
      cursor: unset;
    }
    &--selected::before {
      content: "";
      position: absolute;
      left: 15%;
      bottom: 0;
      width: 70%;
      border-radius: 5px;
      border-top: 4px solid rgb(96, 110, 240);
      animation: fadeIn 1s;
    }
  }
  &__tab-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  &__new-assignment {
    display: flex;
    flex-direction: column;
    padding: 50px;
    &--tab-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    &--tab {
      padding: 8px 12px;
      margin: 0 5px;
      border: 1px solid black;
      font-size: 1.1rem;
      border-radius: 5px;
      min-width: 120px;
      text-align: center;
      font-family: "Fellix-Bold";
      cursor: pointer;
      &--selected {
        background: rgb(4, 141, 146);
        color: white;
        border-color: rgb(4, 141, 146);
        cursor: pointer;
        box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      }
    }
  }
  &__settings {
    display: flex;
    flex-direction: column;

    &--due-date {
      &--text {
        margin: 10px auto;
        color: white;
        font-family: "Fellix-Bold";
      }

      &--option {
        margin: 0px auto 20px;
        color: white;
        font-family: "Fellix-Bold";
        font-size: 0.97rem;
      }
    }
    &--submit {
      color: white;
      font-family: "Fellix-Bold";
      border-radius: 4px;
      background: rgb(4, 141, 146);
      padding: 16px 43px;
      width: 210px;
      align-self: center;
      margin: 15px 0;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.3rem;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
    }
    &--due-date-container {
      margin: 15px 0;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      background: #3d6bea;
      padding: 20px 30px;
      width: 50%;
      align-self: center;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      font-size: 1.2rem;
      font-weight: bolder;
      input {
        width: 35%;
        align-self: center;
      }
    }
    &--button-text {
      display: flex;
      flex-direction: column;
    }
    &__moonrok-button-img {
      width: 100px;
      object-fit: cover;
    }
    &--class-text {
      margin-right: 20px;
      font-family: "Fellix-SemiBold";
    }
    &--class {
      padding: 8px 12px;
      background-color: white;
      margin: 0 5px;
      font-size: 0.9rem;
      border: 1px solid black;
      border-radius: 5px;
      min-width: 120px;
      text-align: center;
      font-weight: bolder;
      cursor: pointer;
      &--selected {
        font-family: "Fellix-Bold";
        background: #e93370;
        color: white;
        border-color: #e93370;
        box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      }
    }
    &--class-row {
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 25px 0 12px;
    }
    &--select-students {
      padding: 12px 20px;

      margin: 15px 0;
      border: 1px solid black;
      font-size: 1.2rem;
      font-family: "Fellix-SemiBold";
      border-radius: 5px;
      width: 20%;
      color: white;
      text-align: center;
      background: #3d6bea;
      border-color: #3d6bea;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);

      align-self: center;
      &:hover {
        cursor: pointer;
        background: #183faa;
        border-color: #183faa;
        color: white;
      }
    }
    &--homework {
      padding: 12px 20px;

      margin: 15px 0;
      border: 1px solid black;
      font-size: 1.2rem;
      font-family: "Fellix-SemiBold";
      border-radius: 5px;
      width: 20%;
      color: white;
      text-align: center;
      background: #3d6bea;
      border-color: #3d6bea;
      align-self: center;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      &:hover {
        cursor: pointer;
        background: #183faa;
        border-color: #183faa;
        color: white;
      }
    }
    &--subject-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      width: 60%;
      padding: 20px;
      border: 2px solid #ff6498;
      border-radius: 8px;
      background-color: #f5ffff;
      box-shadow: 0px 5px 10px 5px #cbdfcb;
      margin: 15px;
    }
    &--subject {
      padding: 8px 12px;
      margin: 0 10px;
      border: 1px solid black;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      min-width: 120px;
      background-color: #ffffff;
      &--selected {
        font-family: "Fellix-Bold";
        background: #e93370;
        color: white;
        border-color: #e93370;
        box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      }
    }
    &--location-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: 90%;
      max-width: 630px;
      padding: 20px;
      border: 2px solid #ff6498;
      background-color: #f5ffff;
      border-radius: 8px;
      box-shadow: 0px 5px 10px 5px #cbdfcb;
      margin: 15px;
    }
    &--location {
      background-color: #ffffff;
      margin: 0 10px;
      border: 1px solid black;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      min-width: 140px;
      height: 100px;
      padding: 20px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        width: 100%;
        align-self: center;
      }
      &--selected {
        font-family: "Fellix-Bold";
        box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
        background: #e93370;
        color: white;
        border-color: #e93370;
      }
    }
    &--frequency-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      width: 50%;
      padding: 10px;
      border: 1px solid rgb(57, 73, 216);
      border-radius: 5px;
      box-shadow: 0px 5px 10px 5px #cbdfcb;
      margin: 15px;
      text-align: center;
    }
    &--frequency {
      padding: 8px 12px;
      margin: 0 10px;
      border: 1px solid black;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      width: 20%;
      &--selected {
        font-family: "Fellix-Bold";
        background: #e93370;
        color: white;
        border-color: #e93370;
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
