.moonrock-header {
  font-family: "Fellix-Regular";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  color: white;
  padding: 7px 60px;
  box-shadow: 0 4px 5px -2px rgb(185, 128, 23);
  z-index: 1;
  &__logo {
    font-family: Arial, Helvetica, sans-serif;

    width: 70px;
  }
  &__close {
    width: 50px;
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
      width: 55px;
    }
  }
}
