.buttons-container {
  &__buttons-container {
    display: flex;
    flex-direction: row;
  }

  &__arrow-down {
    margin-left: 7px;
  }

  &__reading-button-text {
    position: absolute;
    bottom: 20px;
    z-index: 1;
    color: white;
  }

  &__reading-button-img {
    width: 70px;
    margin: auto;
    transform: translateY(-10px);
  }
  &__moonrok-button-img {
    width: 110px;
    margin: auto;
    transform: translateY(-10px);
  }
  &__go-to-stories {
    margin: 2rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 7px 17px #ff5e62;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #ff9966, #ff5e62, #fd6e0e, #e04305);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 15px 25px #ff5e62;
    }
  }

  &__go-to-worksheets {
    margin: 2rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 7px 17px #e73c7e;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #ff416c, #e73c7e, #ff4b2b, #009fff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 15px 25px #e73c7e;
    }
  }

  &__go-to-games {
    margin: 2rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 7px 17px #01b3b3;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #04be86, #06cc80, #0575e6, #01b3b3);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 15px 25px #01b3b3;
    }
  }

  &__go-to-MoonRok {
    margin: 2rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 7px 17px #4946ff;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #c840f1, #4946ff, #6427f3, #4759ff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 15px 25px #4946ff;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
