.header {
  font-family: "Fellix-Regular";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(45deg, #6967f7, #3b96ff, #4c83f1, #6798f7);
  color: white;
  padding: 12px 20px;
  &__logo {
    font-family: Arial, Helvetica, sans-serif;
    &--top {
      font-family: "Fellix-Bold";
      font-size: 2.5rem;
    }
    &--bottom {
      font-family: "Fellix-Regular";
      font-size: 1.2rem;
      letter-spacing: 0.2rem;
    }
  }
  &__user {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
