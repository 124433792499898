.subscription {
  background-color: #fff7dd;
  z-index: -1;
  width: 100%;
  height: 100%;
  &__banner {
    background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/space-bg-footer.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%;

    &--img-holder {
      width: 50vw;
      margin: auto;
      padding-top: 20px;
    }

    &--img {
      width: 140px;
      transition: all 0.2s ease-in-out;
      animation-name: float;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      -webkit-animation-name: float;
      -ms-animation-name: float;
      -ms-animation-duration: 10s;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      -ms-animation-timing-function: ease-in-out;
    }
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    &--monthly {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 40px;
      width: 140px;
      padding: 4px 14px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Fellix-Bold";
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      border: 2px solid #fdaf1fef;
      border-right: none;
    }

    &--yearly {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 40px;
      width: 140px;
      padding: 4px 14px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Fellix-Bold";
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      border: 2px solid #fdaf1fef;
      border-left: none;
    }

    &--selected {
      background-color: #fdaf1fef;
      color: white;
    }

    &--deselected {
    }
  }

  &__perks-card {
    width: 700px;
    height: 470px;
    background-image: url("https://moonrock-images.s3.us-east-2.amazonaws.com/ui_goldenastroid_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    margin: auto;
    margin-top: 20px;

    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--asteroid-holder {
      padding-top: 14px;
      padding-left: 35px;
      width: 110px;
    }
    &--asteroid-img {
      width: 90px;
    }

    &--pickaxe-holder {
      padding-top: 14px;
      padding-right: 35px;
      width: 110px;
    }
    &--pickaxe-img {
      width: 90px;
    }

    &--title-holder {
      padding-top: 14px;

      font-size: 1.8rem;
      font-family: "Fellix-Bold";
      color: white;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }

    &--member-button {
      width: 270px;
      height: 100px;
      background-image: url("https://moonrock-images.s3.us-east-2.amazonaws.com/ui_golden_button.png");
      background-size: 100%;
      background-repeat: no-repeat;
      font-family: "Fellix-Bold";

      position: absolute;
      right: 30%;
      top: 45%;
      transition: 0.2s;

      &:hover {
        right: 29.7%;
        top: 44.7%;
        cursor: pointer;
        opacity: 0.9;
        width: 275px;
        height: 105px;
      }
    }
    &--buy-membership {
      padding-top: 37px;
      font-family: "Fellix-Bold";
      text-transform: uppercase;
      font-size: 1.4rem;
      color: white;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }

    &--price-container {
      display: flex;
      flex-direction: column;
    }

    &--dollar-sign {
      font-size: 1.7rem;
      padding-bottom: 10px;
    }

    &--price {
      font-size: 4rem;
      font-family: "Fellix-Bold";
      color: white;
      text-shadow: 1px 1px 2px rgb(155, 101, 0);
    }

    &--per-month {
      font-family: "Fellix-Bold";
      color: white;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    &--kids {
      font-family: "Fellix-Bold";
      font-size: 1.4rem;
      position: absolute;
      left: 10%;
      bottom: 16%;
      color: white;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }

    &--parents {
      font-family: "Fellix-Bold";
      font-size: 1.4rem;
      position: absolute;
      left: 50%;
      bottom: 16%;
      color: white;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
  }
}

@keyframes float {
  0% {
    transform: rotate(4deg) translate(-5px, 0px);
  }
  50% {
    transform: rotate(-4deg) translate(0px, 20px) scale(1);
  }

  100% {
    transform: rotate(4deg) translate(-5px, 0px);
  }
}

@-ms-keyframes float {
  0% {
    -ms-transform: rotate(4deg) translate(0px, 0px);
  }
  50% {
    -ms-transform: rotate(-2deg) translate(-5px, -10px) scale(0.9);
  }
  100% {
    -ms-transform: rotate(4deg) translate(0px, 0px);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: rotate(0deg) translate(0px, 0px);
  }
  50% {
    -webkit-transform: rotate(-2deg) translate(-5px, -10px) scale(0.9);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(0px, 0px);
  }
}
