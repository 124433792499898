.notes-modal {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid #333;

    &--name-dropdown-wrapper {
      display: flex;
      flex-direction: row;
    }

    &--title {
      font-family: "Fellix-Bold";
    }
    &--create-new {
      font-family: "Fellix-Bold";
      border-radius: 8px;
      padding: 8px 12px;
      border: 2px solid #333;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        background-color: #333;
        color: white;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--left-container {
      width: 28%;
      overflow-y: auto;
      max-height: 70vh;
      background-color: #f1f9ff;
      border-right: 2px solid #333;
    }

    &--right-container {
      width: 67%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }

    &--notes-list {
      display: flex;
      flex-direction: column;
      padding-right: 8px;
    }

    &--list-cell {
      height: 125px;
      border-bottom: 1px solid gray;
      padding: 7px;

      &:hover {
        cursor: pointer;
        transform: scale(1.01);
      }
    }

    &--cell-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &--cell-date-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      text-align: left;
    }
    &--cell-date {
      font-family: "Fellix-SemiBold";
      text-align: left;
    }
    &--cell-label-internal {
      font-family: "Fellix-SemiBold";
      text-align: left;
      color: rgb(131, 120, 196);
      font-size: 0.9rem;
      margin: -4px 0px 4px;
    }
    &--cell-label-shared {
      font-family: "Fellix-SemiBold";
      text-align: left;
      color: rgb(88, 196, 136);
      font-size: 0.9rem;
      margin: -4px 0px 4px;
    }
    &--cell-title {
      font-family: "Fellix-Bold";
      text-align: left;
      color: rgb(105, 105, 105);
      font-size: 0.97rem;
      margin: 7px 0px 2px;
    }

    &--cell-tags {
      display: flex;
      flex-direction: row;
    }
    &--cell-tag {
      width: 17px;
      height: 18px;
      border-radius: 3px;
      margin: 4px;
    }

    &--cell-note-preview {
      font-family: "Fellix";
      font-size: 0.9rem;
      color: gray;
      text-align: left;
    }

    &--note-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--note-tags-wrapper {
      display: flex;
      flex-direction: row;
      width: 340px;
      height: 70px;
      position: relative;
      overflow-x: auto;
      align-items: center;
    }

    &--note-tags {
      position: relative;
      padding: 7px 12px;
      border-radius: 7px;
      margin-right: 7px;
      min-width: fit-content;

      font-size: 0.97rem;
      color: white;
      font-family: "Fellix-Bold";
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      transition: 0.1s;

      &:hover {
        cursor: pointer;
        transform: scale(1.01);
      }
    }
    &--note-tags-add {
      padding: 7px 12px;
      border-radius: 7px;
      margin-right: 7px;
      min-width: 70px;
      max-height: 21px;
      font-size: 0.97rem;
      border: 1px solid #333;
      color: #333;
      font-family: "Fellix-Bold";
      transition: 0.1s;
      &:hover {
        cursor: pointer;
        color: white;
        background-color: #333;
      }
    }
    &--note-tags-delete {
      position: absolute;
      top: -5px;
      right: -3px;
      background-color: whitesmoke;
      color: black;
      border: 1px solid brown;
      border-radius: 50%;
      cursor: pointer;
    }

    &--note-delete {
      color: #b43c3c;
      font-family: "Fellix-SemiBold";
      text-decoration: underline;
      font-size: 0.9rem;

      &:hover {
        cursor: pointer;
        font-family: "Fellix-Bold";
      }
    }

    &--note-date-shared-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 23px 10px 17px;
    }

    &--note-date {
      font-family: "Fellix-Bold";
      text-align: left;
    }

    &--note-dropdown {
      padding: 8px 12px;
      border: 1px solid #333;
      border-radius: 7px;
      font-family: "Fellix-SemiBold";
      font-size: 0.94rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }

    &--note-dropdown-arrow {
      margin-left: 7px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--note-label-copy-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 14px 17px 4px;
    }

    &--note-label {
      font-size: 0.84rem;
      color: gray;
      font-family: "Fellix-SemiBold";
    }

    &--note-copy {
      color: #4d7bd7;
      font-size: 0.84rem;
      font-family: "Fellix-SemiBold";
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    &--note-body-wrapper {
      font-family: "Fellix-Bold";
      border-radius: 8px;
      padding: 12px;
      border: 1px solid rgb(199, 199, 199);
      transition: 0.2s;
      background-color: #f1f9ff;

      &:hover {
        cursor: text;
        border: 1px solid rgb(82, 82, 82);
      }
    }
    &--note-body-title-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
    }

    &--note-body-title-label {
      margin-right: 12px;
    }

    &--note-body-title {
      font-family: "Fellix-SemiBold";
      border-radius: 8px;
      padding: 8px 12px;
      border: 1px solid rgb(199, 199, 199);
      transition: 0.2s;
      min-width: 140px;
      background-color: white;

      &:hover {
        cursor: text;
        border: 1px solid rgb(82, 82, 82);
      }
      &:focus {
        outline: none;
        border: 2px solid rgb(38, 170, 115);
      }
    }

    &--note-body-text {
      text-align: left;
      min-height: 170px;
      padding: 12px;
      font-family: "Fellix";
      background-color: white;
      border-radius: 8px;
      border: 1px solid rgb(199, 199, 199);
      &:hover {
        cursor: text;
        border: 1px solid rgb(82, 82, 82);
      }

      &:focus {
        outline: none;
        border: 2px solid rgb(38, 170, 115);
      }
    }
  }
  &--overlay {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

.lgb {
  background-color: #03c28c;
}
.fp {
  background-color: #049b9b;
}
.gdt {
  background-color: #0b7ff3;
}
.sm {
  background-color: #655dd6;
}
.cl {
  background-color: #7a7a7a;
}
.ml {
  background-color: #187461;
}
.reb {
  background-color: #00cec9;
}
.eb {
  background-color: #105d97;
}
.ef {
  background-color: #392aa8;
}
.sb {
  background-color: #515658;
}
.sl {
  background-color: #e4a621;
}
.fd {
  background-color: #ca6954;
}
.pg {
  background-color: #ff6464;
}
.p8p {
  background-color: #df3570;
}
.ar {
  background-color: #202020;
}
.by {
  background-color: #cc8b12;
}
.ov {
  background-color: #e17055;
}
.cg {
  background-color: #8d2020;
}
.pa {
  background-color: #b85082;
}
.do {
  background-color: #028fa8;
}

.color-picker {
  position: fixed;
  width: 100px;
  height: 200px;
  top: 31%;
  left: 52%;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &--tag {
    font-size: 0.97rem;
    margin-bottom: 5%;
    font-family: "Fellix-Bold";
    border: 1px solid black;
    border-radius: 3px;
    padding: 2px 0;
    &:hover {
      cursor: pointer;
    }
  }
  &--delete {
    font-size: 0.97rem;
    margin-top: 5%;
    font-family: "Fellix-Bold";
    color: #b43c3c;
    cursor: pointer;
  }
  &--colors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }

  &--div {
    box-shadow: 0px 2px 4px rgb(89 126 156 / 60%);
    border-radius: 3px;
    width: 19%;
    height: 14%;
    margin: 2% 3%;
    cursor: pointer;
  }
}
