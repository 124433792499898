.story-row {
  &--tags {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &--selected-tag {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 4px 12px;
    font-size: 1rem;
    font-family: "Fellix-SemiBold";
    border-radius: 8px;
    color: white;
    background-color: rgb(4, 184, 124);
  }
  &--loaded {
    display: grid;
    width: 90%;
    background-color: white;
    grid-template-columns: 30% 15% 15% 25% 15%;
    align-items: center;
    padding: 5px 9px;
    border: 1px solid whitesmoke;
    border-radius: 8px;
    box-shadow: 0px 3px 7px rgba(89, 126, 156, 0.3);
    margin: 12px auto;
    cursor: pointer;
    font-family: "Fellix-SemiBold";
  }
  &--image {
    background-size: cover;
    height: 170px;
    margin: 2px 0px 0px;
    background-position: center;
    border-radius: 8px;
    box-shadow: 0px 3px 7px rgba(89, 126, 156, 0.4);
  }
  &--selected {
    border: 1px solid #d1e0fd;
    border-radius: 8px;
    box-shadow: 0px 3px 7px rgb(89 126 156 / 85%);

    font-family: "Fellix-SemiBold";
    background: #e2ffd9;
  }
}
