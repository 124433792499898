.teacher-portal {
  font-family: "Fellix-Regular";
  background-color: #fafeff;

  &__order-container {
    min-width: 40px;
  }

  &__sorter-types {
    display: flex;
    flex-direction: row;
  }

  &__sorting-type {
    min-width: 100px;
    align-self: center;
    color: grey;
    cursor: pointer;
    &--selected {
      min-width: 93px;
      font-family: "Fellix-Bold";
      color: black;
      text-decoration: underline;
      align-self: center;
    }
  }

  &__sorting-type-container {
    display: flex;
    flex-direction: row;
  }

  &__subjects-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__subjects-filter {
    &--subjects-container {
      display: flex;
      flex-direction: row;
    }
    &--title {
      font-size: 1rem;
      font-family: "Fellix-Bold";
    }
    &--subject {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #e0e0e0;
      border: 1px solid #d1d0d0;
      font-family: "Fellix-SemiBold";
      padding: 12px 18px;
      width: 60px;
      height: 30px;
      margin: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;

      &--selected {
        background: #3b82f4;
        color: white;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);
      }
    }
  }
  &__tags-filter {
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      // &:last-of-type {
      // }
    }
    &--title {
      margin-bottom: 10px;
      margin-left: 18px;
      font-size: 1rem;
      text-align: left;
      font-family: "Fellix-Bold";
    }
    &--tag {
      font-size: 0.9rem;
      font-family: "Fellix-SemiBold";
      width: 20%;
      padding: 8px 18px;
      border-radius: 4px;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
      border: 2px solid #d1d0d0;
      background-color: white;
      cursor: pointer;
      transition: 0.2s;

      &--overdue {
        background: white;
        color: #b33030;
        font-family: "Fellix-Bold";
        border-color: #b33030;
        opacity: 0.85;
        &--selected {
          background-color: #b33030;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }
      &--level-up {
        background: white;
        color: #11a397;
        font-family: "Fellix-Bold";
        border-color: #11a397;
        &--selected {
          background-color: #11a397;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }
      &--at-risk {
        background: white;
        color: #eb753f;
        font-family: "Fellix-Bold";
        border-color: #eb753f;
        &--selected {
          background-color: #eb753f;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }
      &--reading-recovery {
        background: white;
        color: #4b1cb8;
        font-family: "Fellix-Bold";
        border-color: #4b1cb8;
        &--selected {
          background-color: #4b1cb8;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }
      &--iep {
        background: white;
        color: #bd2088;
        font-family: "Fellix-Bold";
        border-color: #bd2088;
        &--selected {
          background-color: #bd2088;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }
      &--active-parent {
        background: white;
        color: #0763f7;
        font-family: "Fellix-Bold";
        border-color: #0763f7;
        &--selected {
          background-color: #0763f7;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }
      &--exceeds-expectations {
        background: white;
        color: #fda90e;
        font-family: "Fellix-Bold";
        border-color: #fda90e;
        &--selected {
          background-color: #fda90e;
          color: white;
          opacity: 1;
          box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
        }
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);
      }
    }
  }
  &__sort-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #d1d0d0;
    padding-bottom: 12px;
    padding-top: 12px;
  }

  &__sorter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 8px;
    width: 100%;
    border-radius: 8px;
  }

  &__sorter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    width: 43%;
  }
  &__sort-by {
    margin-right: 7px;
    min-width: 60px;
  }

  &__ascending {
    padding: 5px 7px;
    border-radius: 8px;

    cursor: pointer;
    background-color: #edf3ff;
    font-family: "Fellix-Bold";
    box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.4);
  }

  &__results-indicator {
    text-align: left;
    margin-left: 12px;
    margin-top: 17px;
  }

  &__search-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 250px;
    background-color: white;

    border-radius: 4px;
    padding: 0px 12px;
    justify-content: flex-start;

    color: #1c1c1e;
    font-size: 1.6rem;

    border: 1px solid #d7dae0;

    overflow: hidden;

    box-shadow: 0px 3px 7px rgba(89, 126, 156, 0.4);

    &--input {
      background-color: white;
      border: none;
      margin-left: 7px;
      padding: 10px 8px;
      font-family: "Fellix" !important;
      font-size: 1.2rem;

      &:focus {
        outline: none;
        box-shadow: 0 5px 15px rgba(255, 255, 255, 0.4);
        padding: 12px 8px;

        font-family: "Fellix" !important;
      }
    }
  }

  &__nametags-filter-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__classroom-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px 0px 0px;
  }
  &__classroom-search {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px 0 10px;
  }

  &__nav-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: #edf3ff;
    margin: 0 0 20px;
    height: 60px;
    box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.8);

    &--item {
      background-color: #fff;
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 10px;
      font-family: "Fellix-SemiBold";
      cursor: pointer;
      border: 0.5px solid #7c7c7c;
      border-top: none;

      &--selected {
        color: white;
        font-family: "Fellix-Bold";
        background: #048d92;
        border: 1px solid #048d92;
        border-bottom: 0.5px solid #7c7c7c;
        border-top: none;
      }
    }
  }
  &__add-student {
    cursor: pointer;
    background-color: white;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 7px 0px;
    font-family: "Fellix-SemiBold";
    width: 80%;
  }

  &__classroom-details-container {
    background: #3d6bea;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    overflow: scroll;
    padding: 25px 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 2px solid #3d6bea;
    border-right: 2px solid #3d6bea;
    border-bottom: 2px solid #3d6bea;
  }

  &__classroom-details-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: white;
    border-radius: 5px;
    padding: 10px 10px;
    max-width: 230px;
    width: 30%;
    height: 90px;
    margin: 0 20px;
    text-align: center;
    box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.4);
    border: 1px solid #3d6bea;
  }

  &__quick-assign {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Fellix-SemiBold";

    font-size: 1.2rem;
  }

  &__class-code {
    &--identifier {
      font-family: "Fellix-SemiBold";
    }
    &--value {
      color: white;
      cursor: pointer;
      background-color: #3d6bea;
      padding: 8px 12px;
      border-radius: 8px;
      margin: 7px 0px;
      font-family: "Fellix-SemiBold";
      width: 80%;
    }
  }

  &__students-length {
    &--identifier {
      font-family: "Fellix-SemiBold";
    }
    &--value {
      font-family: "Fellix-SemiBold";

      font-size: 1.4rem;
    }
  }

  &__classrooms {
    width: 93%;
    margin: auto;
  }

  &__classrooms-wrapper {
    width: 93%;
    margin: auto;
    border-radius: 5px;
    border: 1px solid #d1d0d0;
    border-top: none;
    border-bottom: none;
    background: whitesmoke;
  }

  &__classroom-name {
    cursor: pointer;
    background: white;
    padding: 10px 10px;
    width: 100%;
    border: 1px solid #a5a5a5;
    border-bottom: 2px solid #3d6bea;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    // &:first-of-type {
    // }
    &:last-of-type {
      width: 30%;
    }
    &--active {
      border: 2px solid #3d6bea;
      border-bottom: none;
      background-color: #3d6bea;
      font-family: "Fellix-Bold";
      color: white;
    }
  }

  &__student-container {
    border: 2px solid #3d6bea;
    background-color: #fdfeff;
    border-radius: 5px;
    margin: 25px 0px;
    padding: 0;
    box-shadow: 0px 5px 10px rgb(89 126 156 / 90%);
  }

  &__student {
    &--row--all {
      background-color: #e7f0ff;

      margin-top: 20px;
      border-top: 1px solid #3b82f4;
    }

    &--row--math {
      background-color: #ffeff8;
      border-top: 1px solid #bd2088;
      margin-top: 20px;
    }

    &--row--english {
      background-color: #f2fff9;
      border-top: 1px solid #209967;

      margin-top: 20px;
    }

    &--row--reading {
      background-color: #f5f1ff;
      border-top: 1px solid #824ffc;
      margin-top: 20px;
    }
    &--grade {
      text-align: left;
    }
    &--parent-container {
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    }

    &--parent-email-container {
      border: 1px solid #d7dae0;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
      background-color: white;
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 0.84rem;
      font-family: "Fellix-SemiBold";

      margin: 0px 8px;
      cursor: pointer;
      transition: 0.2s;
    }

    &--parent-email-copy-container {
      color: #4d7bd7;
      margin-top: 4px;
      font-size: 0.84rem;
      font-family: "Fellix-SemiBold";
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    &--subjects-filter {
      display: flex;
      flex-direction: row;
    }

    &--parent-not-connected {
      background: #ca5a5a;
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 0.84rem;
      font-family: "Fellix-SemiBold";
      box-shadow: 0px 5px 10px rgb(89 126 156 / 20%);
      color: white;
      margin: 0px 8px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);
      }
    }

    &--subject {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: white;
      padding: 12px 18px;
      font-size: 0.84rem;
      font-family: "Fellix-SemiBold";
      width: 50px;
      height: 20px;
      margin: 0px 5px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;
      box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);

      &--all {
        border: 1px solid #3d6bea;
        &--selected {
          border: 1px solid #3d6bea;
          background: #3d6bea;
          color: white;
          box-shadow: 0px 2px 7px rgba(134, 134, 134, 0.7);
        }
      }
      &--reading {
        border: 1px solid #824ffc;
        &--selected {
          border: 1px solid #824ffc;
          background: #824ffc;
          color: white;
          box-shadow: 0px 2px 7px rgba(134, 134, 134, 0.7);
        }
      }
      &--math {
        border: 1px solid #bd2088;
        &--selected {
          border: 1px solid #bd2088;
          background: #bd2088;
          color: white;
          box-shadow: 0px 2px 7px rgba(134, 134, 134, 0.7);
        }
      }
      &--english {
        border: 1px solid #209967;
        &--selected {
          border: 1px solid #209967;
          background: #209967;
          color: white;
          box-shadow: 0px 2px 7px rgba(134, 134, 134, 0.7);
        }
      }

      &:hover {
        transform: translateY(-1px);
        // box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);
      }
    }
    &--last-active-container {
      padding: 8px 12px;
      background: #edf3ff;
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      font-size: 0.9rem;
      justify-content: space-evenly;
      cursor: pointer;
      border: 1px solid #d1d0d0;
      transition: 0.2s;

      box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.2);
      }
    }

    &--last-active-text {
      font-family: "Fellix-Bold";
      margin-right: 4px;
    }

    &--last-active-days-ago {
      font-family: "Fellix-Bold";
      margin-left: 4px;

      color: rgb(202, 90, 90);
    }

    &--name {
      font-size: 1rem;
      text-align: left;
      font-family: "Fellix-Bold";
    }

    &--tags-container {
      margin-left: 10px;
    }

    &--tag {
      font-size: 0.9rem;
      font-family: "Fellix-SemiBold";
      width: 120px;
      padding: 8px 18px;
      margin-left: 10px;
      border-radius: 4px;
      border: 1px solid #d1d0d0;
      cursor: pointer;
      transition: 0.2s;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 40%);
    }
    &--stat-container {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--stat-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 1rem;
      font-family: "Fellix-Bold";

      &--assign {
        color: white;
        cursor: pointer;
        margin: auto;
        height: 27px;
        margin-bottom: 7px;
        width: 50%;
        padding: 2px 5px;
        border: 1px solid #3b82f4;
        box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.4);
        border-radius: 4px;

        background: #3b82f4;
        transition: 0.2s;

        &:hover {
          box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.7);
          transform: translateY(-3px);
        }
      }
    }

    &--stat-box {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      min-width: 210px;
      height: 160px;
      margin: 0px 10px;
      background-color: white;
      border: 1px solid #3b82f4;
      border-radius: 4px;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 70%);

      &--notes-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
      &--notes {
        min-width: 400px;
      }

      &--comp-percentage {
        color: #3b82f4;
        font-size: 1.4rem;
        font-family: "Fellix-Bold";
        margin-bottom: 10px;
      }

      &--user-pass-wrapper {
        flex-grow: 1;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      &--user-pass-title {
        font-family: "Fellix-SemiBold";
      }

      &--username {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      &--password {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      &--copy {
        cursor: pointer;
        margin: auto;

        margin-bottom: 17px;
        width: 50%;
        padding: 12px 5px;
        border: 1px solid #d1d0d0;
        box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.4);
        border-radius: 4px;

        background: #edf3ff;
        transition: 0.2s;

        &:hover {
          box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.7);
          transform: translateY(-3px);
        }
      }

      &--tracked-word {
        display: flex;
        flex-direction: row;
      }

      &--row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        cursor: pointer;

        &:hover {
          background-color: #edf3ff;
        }
      }

      &--row-assigned {
        border-bottom: 1px solid #d1d0d0;
        flex-grow: 1;
        width: 100%;
        font-family: "Fellix-Bold";

        &:last-of-type {
          border-bottom: none;
        }
      }
      &--to-do-text {
        font-family: "Fellix-SemiBold";
        margin-left: 23px;
        color: #4c84d6;
      }
      &--to-do-value {
        font-family: "Fellix-SemiBold";
        margin-right: 23px;
        color: #4c84d6;
        font-size: 1.4rem;
      }
      &--overdue-text {
        font-family: "Fellix-SemiBold";
        margin-left: 23px;
        color: #d65861;
      }
      &--overdue-value {
        font-family: "Fellix-SemiBold";
        margin-right: 23px;
        color: #d65861;
        font-size: 1.4rem;
      }
      &--completed-text {
        font-family: "Fellix-SemiBold";
        margin-left: 23px;

        color: rgb(17, 163, 151);
      }
      &--completed-value {
        font-family: "Fellix-SemiBold";
        margin-right: 23px;
        color: rgb(17, 163, 151);
        font-size: 1.4rem;
      }

      &--level-container {
        width: 50%;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        display: flex;
      }

      &--level-text {
        font-size: 1.2rem;
        font-family: "Fellix-Bold";
      }

      &--level-value {
        font-size: 1rem;
        font-family: "Fellix-Bold";
        width: 40px;
        height: 40px;
        background: rgb(17, 163, 151);
        color: white;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
      }

      &--change-level {
        cursor: pointer;
        font-family: "Fellix-SemiBold";
        border-top: 1px solid #d1d0d0;
        padding: 10px 0px;
        width: 100%;
        align-self: flex-end;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background: #3b82f4;
        color: white;
      }

      &--tracked-words-container {
        width: 50%;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        display: flex;
      }

      &--see-more {
        cursor: pointer;
        font-family: "Fellix-SemiBold";
        border-top: 1px solid #d1d0d0;
        padding: 10px 0px;
        width: 100%;
        align-self: flex-end;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background: #edf3ff;
      }

      &--copy-text {
        color: #4d7bd7;
        margin-top: 4px;
        font-size: 0.93rem;
        font-family: "Fellix-SemiBold";
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 20px 20px;
      align-items: center;
      &:nth-of-type(2) {
        padding: 4px 20px;
        justify-content: flex-start;
      }
      &:nth-of-type(3) {
        justify-content: unset;
        padding: 30px 0px;
        overflow: scroll;
      }
    }
  }

  &__classroom-name-container {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 500px) {
    &__classroom-filters {
      flex-direction: column;
    }
    &__classroom-search {
      flex-direction: column;
    }
  }
}

.reading-bg {
  background-color: #824ffc;
}
.math-bg {
  background-color: #bd2088;
}
.english-bg {
  background-color: #209967;
}

.reading-border {
  border-color: #824ffc;
}
.math-border {
  border-color: #bd2088;
}
.english-border {
  border-color: #209967;
}
