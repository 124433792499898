@import "./partials/teacherPortal";
@import "./partials/modal";
@import "./partials/buttonsContainer";
@import "./partials/header";
@import "./partials/storyRow";
@import "./partials/assignmentHub";
@import "./partials/subscription";
@import "./partials/moonrockHeader";
@import "./partials/notes-modal";

@font-face {
  font-family: "Fellix-Thin";
  src: url("../Fonts/Fellix-Thin.woff") format("woff");
}

@font-face {
  font-family: "Fellix-Regular";
  src: url("../Fonts/Fellix-Regular.woff") format("woff");
}

@font-face {
  font-family: "Fellix-Medium";
  src: url("../Fonts/Fellix-Medium.woff") format("woff");
}

@font-face {
  font-family: "Fellix-SemiBold";
  src: url("../Fonts/Fellix-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Fellix-Bold";
  src: url("../Fonts/Fellix-Bold.woff") format("woff");
}

@font-face {
  font-family: "Fellix-ExtraBold";
  src: url("../Fonts/Fellix-ExtraBold.woff") format("woff");
}

.level-A {
  background-color: #fecc47;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #fecc47;
  }
}

.level-B {
  background-color: #f9a557;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #f9a557;
  }
}

.level-C {
  background-color: #f58d5d;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #f58d5d;
  }
}

.level-D {
  background-color: #ff7222;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #ff7222;
  }
}

.level-E {
  background-color: #79cecb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #79cecb;
  }
}

.level-F {
  background-color: #3eb8c8;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #3eb8c8;
  }
}

.level-G {
  background-color: #38aaab;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #38aaab;
  }
}

.level-H {
  background-color: #ff839c;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #ff839c;
  }
}

.level-I {
  background-color: #e35072;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #e35072;
  }
}

.level-J {
  background-color: #f5357b;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #f5357b;
  }
}

.level-K {
  background-color: #b466d2;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #b466d2;
  }
}

.level-L {
  background-color: #ce4da5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #ce4da5;
  }
}

.level-M {
  background-color: #a03d7f;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #a03d7f;
  }
}

.level-N {
  background-color: #622fbc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #622fbc;
  }
}

.level-O {
  background-color: #c1c82f;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #c1c82f;
  }
}

.level-P {
  background-color: #a0ac38;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #a0ac38;
  }
}

.level-Q {
  background-color: #fd8c12;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #fd8c12;
  }
}

.level-R {
  background-color: #42c4e6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #42c4e6;
  }
}

.level-S {
  background-color: #f79d3d;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #f79d3d;
  }
}

.level-T {
  background-color: #45e33e;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #45e33e;
  }
}

.level-U {
  background-color: #8c1d44;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #8c1d44;
  }
}

.level-V {
  background-color: #28df0b;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #28df0b;
  }
}

.level-W {
  background-color: #c406e6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #c406e6;
  }
}

.level-X {
  background-color: #8b3d3d;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #8b3d3d;
  }
}

.level-Y {
  background-color: #52538f;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #52538f;
  }
}

.level-Z {
  background-color: #452749;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px #452749;
  }
}
