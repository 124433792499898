// .StripeElement {
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   color: #495057;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   border: 1px solid #ced4da;
//   border-radius: 4px;
//   padding: 8px 12px 6px 12px;
//   width: 100%;
//   margin: 8px 0px 4px -1px;
//   height: 36px;
//   box-sizing: border-box;
//   transform: translateZ(0);
//   user-select: text;
//   -webkit-user-select: text;
// }

// .StripeElement--focus {
//   border: 1px solid #80bdff;
//   box-shadow: 0 0 3.2px #007bff;
//   outline: 0;
//   border-radius: 4px;
//   transition: box-shadow 0.15s;
// }

// .StripeElement--invalid {
//   border: 1px solid #dc3545;
//   padding: 8px 46px 6px 12px;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   background: transparent;
//   color: #495057;
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' fill='%23DC3545'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 99%;
//   background-position-y: 5px;
//   color: #495057;
// }

// .StripeElement--invalid.StripeElement--focus {
//   box-shadow: 0 0 3.2px #dc3545;
//   outline: 0;
//   border-radius: 4px;
//   transition: box-shadow 0.15s;
// }
// .StripeElement--complete {
//   border: 1px solid #28a745;
//   padding: 8px 46px 6px 12px;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   color: #495057;
//   background: transparent;
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' fill='%2328A745'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 99%;
//   background-position-y: 5px;
// }

// .StripeElement--complete.StripeElement--focus {
//   box-shadow: 0 0 3.2px #28a745;
//   outline: 0;
//   border-radius: 4px;
//   transition: box-shadow 0.15s;
// }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal {
  position: fixed;
  background: white;
  width: 80vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  padding: 0rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 1001999;
  max-height: 90vh;
  box-shadow: 0 5px 10px 0 #363636;
  overflow-y: auto;

  &__prompt-assigned {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 1.1rem;
    transform: translateY(-10px);
    text-align: center;
    font-family: "Fellix-SemiBold";
    margin-bottom: 12px;
  }

  &__to-do {
    &--assignments {
      height: 500px;
      overflow-y: auto;
    }
  }

  &__quick-assign {
    &--confirm {
      margin-top: 50px;
    }
  }

  &__due-date {
    &--container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding: 12px;
      box-shadow: 0 4px 8px rgb(228, 227, 227);
      border-radius: 8px;
      background-color: #3b82f4;
      color: white;
      margin-bottom: 20px;
    }
  }

  &__overdue {
    &--heading {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 23px;
    }
  }

  &__stories-container {
    height: 50vh;
    overflow: auto;
    background-color: #edf3ff;
    border-radius: 8px;
    box-shadow: 0px 3px 7px rgba(89, 126, 156, 0.5);
  }

  &__story-row {
    // display: flex;
    // flex-direction: row;
    &--top {
      font-family: "Fellix-Bold";
      display: grid;
      grid-template-columns: 30% 15% 15% 25% 15%;
      padding: 12px 0px;
    }
  }

  &__completed {
    &--stats-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--tags-container {
      width: 30%;
      display: flex;
      flex-direction: column;
      padding: 0px 25px 0px 12px;
      border-right: 1px solid #d3d3d3;
    }

    &--tags-graded {
      padding: 4px 8px;
      background-color: #049676;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 8px;
      font-family: "Fellix-Bold";
      margin-bottom: 8px;
    }

    &--tags-not-graded {
      padding: 4px 8px;
      background-color: #ca534a;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 8px;
      font-family: "Fellix-Bold";
      margin-bottom: 8px;
    }

    &--tags-homework {
      padding: 4px 8px;
      background-color: #7851e4;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 8px;
      font-family: "Fellix-Bold";
      margin-bottom: 8px;
    }

    &--tags-assessment {
      padding: 4px 8px;
      background-color: #2493fa;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 8px;
      font-family: "Fellix-Bold";
      margin-bottom: 8px;
    }

    &--main-stats {
      width: 60%;
    }

    &--open-link {
      text-decoration: none;
    }
    &--new-tab {
      color: #2196f3;
      font-size: 1.2rem;
      text-shadow: 1px 1px 2px rgba(124, 170, 255, 0.4);
    }

    &--open-tab {
      font-family: "Fellix-Bold";
      color: #2196f3;
      font-size: 0.8rem;
      text-decoration: none;
    }

    &--first-row {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      a {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &--round {
      border-radius: 34px;
      height: 34px;

      &::before {
        border-radius: 50%;
      }
    }

    &--audio {
      height: 47px;
      width: 370px;
      color: white;
    }

    &--stat-prompt {
      font-family: "Fellix-Bold";
    }

    &--notes-prompt {
      font-family: "Fellix-Bold";
      margin: 16px 0px 8px;
      text-align: left;
    }

    &--manual-score-prompt {
      font-family: "Fellix-Bold";
      text-align: left;
      margin-bottom: 8px;
      color: white;
    }

    &--switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      margin-left: 12px;
    }
    &--switch-input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    &--switch-input:checked + &--slider {
      background-color: #2196f3;
    }
    &--switch-input:focus + &--slider {
      box-shadow: 0 0 1px #2196f3;
    }
    &--switch-input:checked + &--slider::before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    &--slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      box-shadow: 0 0 1px #2196f3;
      &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    &--audio-container {
      position: sticky;
      top: -1px;
      z-index: 1;
      background: rgb(84, 144, 255);
      padding: 17px 10px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
    &--notes-container,
    &--manual-score-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(-20px);
    }

    &--notes-container {
      margin-bottom: 0px;
    }

    &--notes-share {
      margin: auto;
      margin-bottom: 20px;
      padding: 8px 12px;
      border-radius: 8px;
      background-color: #049676;
      color: white;
      font-family: "Fellix-Bold";
      width: 50%;

      &:hover {
        cursor: pointer;
      }
    }

    &--manual-score-container {
      background-color: #216bf3;
      padding: 8px;
      margin-bottom: 3px;
      border-radius: 8px;
      align-items: center;
    }
    &--manual-score-input {
      height: 25px;
      width: 20%;
      border: 1px solid gray;
      border-radius: 4px;
      text-align: center;
    }
    &--manual-score-div {
      height: 25px;
      padding: 1px 2px;
      margin-bottom: 16px;

      background-color: white;
      border: 1px solid gray;
      border-radius: 4px;
      width: 20%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &--notes-textarea {
      min-height: 80px;
      max-height: 80px;
      width: 90%;
      text-align: center;
    }
    &--notes-div {
      min-height: 80px;
      max-height: 80px;
      background-color: white;
      width: 90%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      border: 1px solid gray;
      border-radius: 4px;
      padding: 2px;
    }
    &--manual-markup {
      &--manual {
        color: rgb(82, 122, 252);
        font-family: "Fellix-SemiBold";
      }
      &--auto {
        color: rgb(255, 116, 116);
        font-family: "Fellix-SemiBold";
      }
      &--manual,
      &--auto {
        display: flex;
        flex-direction: row;
        align-items: center;

        justify-content: center;
      }
    }

    &--story-date {
      width: 37%;
      font-size: 1rem;
      font-family: "Fellix-Bold";
    }
    &--story-title {
      width: 63%;

      font-size: 1.4rem;
      font-family: "Fellix-Bold";
      margin-top: 8px;
      margin-bottom: 23px;
      color: #3b82f4;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }
    &--go-back,
    &--see-more {
      font-family: "Fellix-SemiBold";
      padding: 8px 12px;
      background: rgb(230, 119, 119);
      width: 20%;
      margin: auto;
      border-radius: 5px;
      color: white;
      cursor: pointer;
    }
    &--assignment-row {
      display: flex;
      flex-direction: column;
      padding: 8px 12px;
      border: 1px solid rgb(148, 148, 148);
      border-radius: 8px;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      margin: 12px 8px;
      background-color: #f0f7ff;
    }
    &--mistake-container {
      display: grid;
      grid-template-columns: auto auto auto;
    }
    &--mistake-counter,
    &--accuracy-counter {
      width: auto;
      margin: 8px auto;
    }
    &--accuracy {
      font-family: "Fellix-Bold";
    }
    &--assignments {
      height: 500px;
      overflow-y: auto;
    }

    &--story-text-container {
      margin: 10px auto;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 60%);
      background-color: white;
      border-radius: 8px;
      border: 1px solid rgb(114, 114, 114);
      padding-bottom: 30px;
    }
    &--story-page {
      margin-bottom: 18px;
      font-size: 1.2rem;
      &--first {
        margin-top: 12px;
      }
    }
    // &--story-line {
    //   position: relative;
    // }
    &--story-word {
      position: relative;
      cursor: pointer;
      border-radius: 4px;
      padding: 3px 6px;
    }
    &--mistakes-header {
      font-size: 1.4rem;
      margin-top: 12px;
    }
    &--mistakes-dropdown {
      z-index: 99999999;
      position: absolute;
      top: 35px;

      background: white;
      display: flex;
      flex-direction: column;

      box-shadow: 0px 5px 10px rgb(89 126 156 / 90%);
      border-radius: 8px;
    }
    &--mistakes-dropdown::after {
      position: absolute;
      top: -9px;
      left: 43%;
      content: "";
      width: 0;
      height: 0;
      border-bottom: solid 10px white;

      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }
    &--mistakes-dropdown::before {
      position: absolute;
      top: -10px;
      left: 43%;
      content: "";
      width: 0;
      height: 0;

      border-bottom: solid 10px rgb(158, 98, 226);
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }

    &--mistake-option {
      padding: 10px 18px;
      font-size: 1rem;
      cursor: pointer;
    }
    &--mistake-option:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &--mistake-option:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &--mistake-word {
      padding: 8px 12px;
      color: black;
      cursor: text;
    }
  }

  &__add-student {
    &--section {
      width: 43%;
      margin: auto;
    }

    &--submit {
      padding: 10px 5px;
      border: none;
      border-radius: 4px;
      font-family: "Fellix-Bold";
      color: white;
      width: 50%;

      background-color: #3b82f4;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #3b82f4;
      }
    }

    &--input-holder {
      width: 80%;
    }

    &--input {
      width: 90%;
      text-align: center;
      padding: 10px 8px;
      font-family: "Fellix-SemiBold";
      border: 1.5px solid #d3d3d3;
      border-radius: 4px;
      resize: none;
      outline: none;
    }

    &--text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 10px 10px;
      height: 160px;

      border-radius: 8px;
      background: #edf3ff;
      text-align: center;
      box-shadow: 0px 5px 10px rgb(89 126 156 / 40%);
      border: 1px solid #d1d0d0;
    }
    &--query-container {
      display: flex;
      flex-direction: row;
      margin: 20px auto;
    }
    &--heading {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    &--student-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
    &--value {
      cursor: pointer;
      background-color: white;
      padding: 8px 12px;

      border: 1px solid #d7dae0;

      border-radius: 8px;
      margin: 7px 0px;
      font-family: "Fellix-SemiBold";
      width: 80%;
    }

    &--copy-text {
      color: #4d7bd7;
      margin-top: 4px;
      font-size: 0.84rem;
      font-family: "Fellix-SemiBold";
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__manage-card {
    &--monthly {
      box-shadow: 0 2px 10px 0 #9bbac2;
      margin: 20px;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;
      height: 100px;
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 2px 10px 0 #6c8287;
      }
    }

    &--annual {
      box-shadow: 0 2px 10px 0 #9bbac2;
      margin: 20px;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;
      height: 100px;
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 2px 10px 0 #6c8287;
      }
    }

    &--current {
      background-color: #50c878;
      color: #fff;
    }

    &--current-plan {
      position: absolute;
      left: 70px;
      background-color: white;
      color: #50c878;
      padding: 8px 12px;
      border-radius: 4px;
    }

    &--cancel {
      color: #a9a9a9;
      text-decoration: underline;
      cursor: pointer;
      width: 170px;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: center;
    }

    &--price-row {
      margin: 10px;
    }

    &--price {
      color: #50c878;
      font-size: 1.4rem;
    }

    &--price-white {
      color: #fff;
      font-size: 1.4rem;
    }

    &--original-price {
      text-decoration: line-through;
      font-size: 1.4rem;
      margin-right: 7px;
      color: #a9a9a9;
    }

    &--go-back {
      margin-bottom: 23px;
    }
    &--confirm-button {
      background-color: #fff;
      border: none;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #9bbac2;
    }
  }

  &__success {
    margin-bottom: 20px;
    color: #50c878;
  }

  &__error {
    margin-bottom: 20px;
    color: #cd5c5c;
  }

  &__child-profiles {
    display: flex;
    flex-direction: column;
  }

  &__option-child {
    cursor: pointer;
    padding: 17px 17px;
    background-color: #f8f8ff;
    box-shadow: 0px 0px 10px 0px #cbdbdf;
    margin: 10px 0px;
    border-radius: 5px;
    transition: 0.2s;

    &:hover {
      background-color: #e5f6e8;
      box-shadow: 0px 0px 10px 0px #50c878;

      padding: 23px 17px;
      transition: 0.1s;
      border-radius: 5px;
    }
  }

  &__change-reading-level {
    padding: 0rem 2rem;

    &--answer {
      border-radius: 5px;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      text-align: center;
      margin-bottom: 23px;
      padding: 10px;
    }

    &--answer-step {
      margin: 10px;
    }

    &--prompt {
      font-size: 1.4rem;
      transform: translateY(-10px);
      text-align: center;
      font-family: "Fellix-SemiBold";
    }

    &--how-to-test {
      color: #3b82f4;
      text-decoration: underline;
      cursor: pointer;
      font-family: "Fellix-Bold";
      font-size: 1rem;
      margin: 4px 0 23px;
      text-align: center;
    }
    &--level {
      color: #fff;
      font-weight: 700;
      border-radius: 4px;
      padding: 6px 9.5px;
      margin: 6px;
      width: 50%;
      height: 30px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    &--level-row {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 7px 0px;
      padding: 7px 12px;
      border: 1px solid #d3d3d3;
      border-radius: 7px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      transition: all 0.25s;

      &:hover {
        box-shadow: 0 2px 10px 0 #50c878;
        background-color: #e5f6e8;
        padding: 17px 12px;
      }
    }
    &--selected {
      box-shadow: 0 2px 10px 0 #50c878;
      background-color: #e5f6e8;
      padding: 17px 12px;
    }
    &--levels-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__stories-read {
    &--line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &--story {
      padding: 20px 0px;
      font-size: 1.3rem;
    }
    &--date {
      margin: auto 0;
    }
  }

  &__recently-learned {
    &--word-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 0px;
    }

    &--word {
      padding: 4px 7px;

      font-size: 1.5rem;
      font-weight: 700;

      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }

    &--date {
      padding: 4px 7px;
      font-size: 1.2rem;
      color: #50c878;
      align-self: center;
    }

    &--unlearn {
      cursor: pointer;
      padding: 4px 7px;
      border: 1.2px solid #333;
      border-radius: 8px;
      color: #333;
      font-size: 1rem;
      font-style: normal;
      align-self: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0 3px 7px #d3d3d3;
      }
    }
  }

  &__tracked-words {
    &--word {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5;
      text-align: left;
      margin-left: 0.2rem;
      background-color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }
    &__back-home-arrow {
      width: 70px;
      padding: 20px;

      &:hover {
        padding-left: 5px;
        padding-right: 35px;
        transition: 0.2s;
      }

      &--img {
        width: 100%;
      }
    }
    &__tracking-container {
      background-color: rgba(233, 220, 214, 0.2);
      height: 90vh;
      overflow-y: auto;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      &--modalize {
        background-color: whitesmoke;
        min-width: 75%;
        width: 75%;
        height: 42.3vh;
        overflow-y: scroll;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        bottom: 1%;
        border-radius: 10px;
        position: fixed;
      }
      &--title {
        background-color: #f4ede3;
        margin: auto;
        padding: 2% 1%;
        font-size: 1.2rem;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        display: flex;
        flex-direction: row;

        &--modalize {
          background-color: whitesmoke;
          text-align: left;
          width: 61%;
          font-size: 1.2rem;
          box-shadow: 0 2px 10px 0 #cbdbdf;
          display: flex;
          flex-direction: row;
          padding: 4% 7%;
          bottom: 43%;

          border-radius: 10px;
          position: fixed;
        }
      }

      &--number {
        font-size: 2rem;
        font-weight: 700;
        color: #e5532a;
        display: inline-block;
        margin-right: 5px;
        margin-left: 2.5rem;
      }
    }

    &--tracking-section {
      padding: 20px 0px;
    }
    &__tracking-section {
      display: flex;
      flex-direction: row;
      margin: auto;

      border-top: 1px solid #333;

      &--times-clicked {
        min-width: 23vw;
        margin-left: 20px;
      }

      &--phonic-word-container {
        display: flex;
        flex-direction: row;
        min-width: 30vw;
        padding-top: 10px;
      }

      &--phonic-part-container {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      &--remove-word {
        border: none;
        background-color: transparent;
        font-weight: 800;
        margin-left: auto;
      }
      &--minus-icon {
        padding: 5px 7px;
        border: 3px solid #e5532a;
        border-radius: 50%;
        color: #e5532a;
        font-size: 1.3rem;
      }
      &--save {
        background-color: white;
        border: none;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        margin-left: 30px;
      }
      &--save-success {
        font-size: 1rem;
        margin-left: 30px;
        margin-top: 7px;
      }

      &-chevron {
        position: fixed;
        bottom: 0;
        font-size: 200%;
      }
      &--phonic-click {
        width: 2.5rem;
        height: 2rem;
        border: 1px solid black;
        &-small {
          width: 2rem;
          height: 1.7rem;
          border: 0.5px solid black;
        }
      }
    }
    &__phonic-part {
      &--first {
        color: #f9a557;
        font-family: "Fellix-Bold";
      }

      &--second {
        color: #ff7222;
        font-family: "Fellix-Bold";
      }

      &--middle {
        color: #e35072;
        font-family: "Fellix-Bold";
      }

      &--penultimate {
        color: #b466d2;
        font-family: "Fellix-Bold";
      }

      &--last {
        color: #3eb8cb;
        font-family: "Fellix-Bold";
      }
    }

    &__phonic-click {
      &--first {
        background-color: #f9a557;
      }

      &--second {
        background-color: #ff7222;
      }

      &--middle {
        background-color: #e35072;
      }

      &--penultimate {
        background-color: #b466d2;
      }

      &--last {
        background-color: #3eb8cb;
      }
    }
  }

  &__payment-options {
    text-align: center;
  }

  &__stripe {
    &--go-back {
      cursor: pointer;
      color: #808080;
      width: 20%;
      margin: auto;
      text-align: center;
    }

    &--selection-info {
      font-size: 1.2rem;
      margin: auto;
      text-align: center;
    }

    &--image-wrapper {
      background-color: #b994ff;
      width: 90px;
      height: 90px;
      text-align: center;
      border-radius: 7px;
      position: relative;
      margin: auto;
      margin-top: 17px;
      margin-bottom: 0px;
      padding: 5% 0;
      width: 60%;
      max-width: 500px;

      > img {
        width: 100px;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &--secure-payment {
      display: flex;
      flex-direction: row;
      margin: auto;
      width: 270px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 40px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    &--lock-icon {
      margin-left: 7px;
    }

    &--powered-by {
      margin: auto;
      text-align: center;
      width: 100px;
    }
    &--payment-form {
      padding: 5%;
      padding-bottom: 0;
      margin: auto;
      position: relative;
      width: 90%;
      max-width: 500px;
      transition-property: opacity, transform;
      transition-duration: 0.35s;
      flex-wrap: nowrap;
      & label {
        width: 15%;
        min-width: 70px;
        padding: 11px 0;
        color: #c4f0ff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & input {
        width: 100%;
        padding: 11px 15px 11px 0;
        color: #fff;
        font-family: "Fellix" !important;
        font-size: 1rem;
        letter-spacing: 0.5px;
        background-color: transparent;
        appearance: none;
        outline: none;
        border-style: none;

        &::placeholder {
          color: #c4f0ff;
          font-family: "Fellix" !important;
          font-size: 1rem;
        }
      }
      & fieldset {
        margin: 0 15px 20px;
        padding: 0;
        border-style: none;
        background-color: #7795f8;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 #9bbac2, inset 0 1px 0 #829fff;
      }
      & .row {
        display: flex;
        -ms-flex-align: center;
        align-items: flex-end;
        margin-left: 15px;
        justify-content: space-between;
        align-content: stretch;
      }
      & .row + .row {
        border-top: 1px solid #819efc;
      }

      & .StripeElement {
        width: 100%;
        padding: 11px 15px 11px 0;
      }
    }
    &--payment-button {
      font-size: 1rem;
      border: none;
      border-radius: 4px;
      font-family: "Fellix-Bold";
      color: white;
      background-color: #50c878;
      box-shadow: 0 2px 10px 0 #9bbac2, inset 0 1px 0 #50c878;
      cursor: pointer;
      display: block;
      width: calc(100% - 30px);
      height: 40px;
      margin: 20px 15px 30px;
      // animation-name: wiggleSlow;

      // animation-duration: 8s;
      // animation-delay: 4s;
      // animation-iteration-count: infinite;
      // animation-timing-function: ease-in-out;
      // -webkit-animation-name: wiggleSlow;
      // -ms-animation-name: wiggleSlow;
      // -ms-animation-duration: 8s;
      // -webkit-animation-duration: 8s;

      // -ms-animation-delay: 4s;
      // -webkit-animation-delay: 4s;

      // -webkit-animation-iteration-count: infinite;
      // -ms-animation-iteration-count: infinite;
      // -webkit-animation-timing-function: ease-in-out;
      // -ms-animation-timing-function: ease-in-out;
    }
  }

  &__close {
    cursor: pointer;
    align-self: flex-end;
    font-family: "Fellix-SemiBold";
    font-size: 1.3rem;
    padding: 10px;
    z-index: 1;
    &--white {
      color: white;
      background-color: #5854a1;
      border-radius: 10%;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }
  &__instead {
    &--link {
      cursor: pointer;
      color: #5854a1;
    }
  }

  &__prompt {
    font-size: 1.2rem;
    transform: translateY(-10px);
    font-family: "Fellix-SemiBold";
  }

  &__register-title {
    font-size: 1.4rem;
    transform: translateY(-10px);
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
  }

  &__button {
    border: 1px solid #c5c5c5;
    background-color: white;
    font-size: 1.2rem;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    padding: 40px;
    width: 100%;
    transition: all 0.25s;
    background-size: contain;
    width: 286px;
    height: 500px;

    &--parent {
      background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/parent-button.png");
      &:hover {
        transform: translateY(-5px);
        box-shadow: 5px 5px 5px #2f30bd;
      }
    }
    &--educator {
      background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/educator-button.png");

      &:hover {
        transform: translateY(-5px);
        box-shadow: 5px 5px 5px #50caa8;
      }
    }
  }

  &__input {
    padding: 1rem 0.5rem;

    font-family: "Fellix-SemiBold" !important;
    margin-bottom: 0.5rem;
    border: 1.5px solid #d3d3d3;
    border-radius: 4px;
    resize: none;
    outline: none;
  }

  &__assigned-label {
    color: #3b82f4;
    font-size: 1rem;
  }

  &__submit {
    padding: 1rem 0.5rem !important;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    color: white;
    width: 50%;
    margin: auto;
    background-color: #3b82f4;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px #3b82f4;
    }
  }

  &__user-role {
    font-size: 1.4rem;
    margin-top: 230px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    &__user-role {
      margin-top: 140px;
    }
    &__facebook-login {
      width: 12rem !important;
    }
  }

  &__you-quote {
    color: #5854a1;
    font-size: 1.2rem;
  }

  &__social-sign-in-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 2rem 0rem;
  }

  &__facebook-button-fix {
    height: 3rem;
    border: none;
    border-radius: 5px;
    background-color: #3c5997;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    width: 80%;
    box-shadow: 0 2px 10px 0 #9bbac2;
  }

  &__facebook-login {
    width: 16rem;
    border: none;
    border-radius: 5px;
    background-color: #3c5997;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      font-size: 1rem;
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px #3c5997;
    }
  }

  &__google-login {
    padding: 0.25rem 0.5rem;
    height: 3rem;
    width: 12rem;
    border: none;
    border-radius: 5px;
    background-color: #e3522a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    width: 80%;
    box-shadow: 0 2px 10px 0 #9bbac2;

    i {
      margin-right: 0.35rem;
      margin-left: 0.25rem;
      font-size: 1rem;
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px #e3522a;
    }
  }
  &__email-button {
    padding: 0.25rem 0.5rem;
    height: 3rem;
    width: 12rem;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__email-button {
    margin: 1rem auto;
    padding: 0.25rem 0.5rem;
    height: 3rem;
    width: 80%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 2px 10px 0 #d3d3d3;
  }

  &__instead--link {
    font-family: "Fellix-Bold";
    font-size: 1.1rem;
    margin-left: 5px;
  }

  &__stripe-h2 {
    margin-top: 223px;
    margin-bottom: 12px;
  }

  &__premium-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3.4rem;
  }

  &__premium-card {
    box-shadow: 0 2px 10px 0 #9bbac2;
    border-radius: 7px;
    padding: 1rem;
    margin: 1rem;
    width: 230px;
    height: 170px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.37);

    &:hover {
      cursor: pointer;
    }

    &--heading {
      font-size: 1.2rem;
      z-index: 1;
      color: #333;
      margin-top: 3.5px;
    }

    &--price {
      font-size: 2rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.93);
    }

    &--original-price {
      text-decoration: line-through;
    }

    &--price-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      justify-content: center;
      margin-top: 17px;
    }

    &--annual-banner {
      box-shadow: 0 2px 10px 0 #9bbac2;
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      color: #3878e2;
      width: 100%;
      padding: 1rem 0rem;
      border-radius: 7px 7px 0 0;
      text-align: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #3878e2;
      }
    }

    &--monthly {
      background-color: #50c878;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #50c878;
      }
    }

    &--annual {
      background-color: #3878e2;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #3878e2;
      }
    }
  }

  &__main-wrapper {
    text-align: center;
  }

  &__modal-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__welcome-to {
    width: 90%;
    max-width: 400px;
    padding: 2rem 1rem 0.5rem;
  }

  &__ribbon-title {
    position: absolute;
    top: 8%;
    left: 15%;
    width: 70%;
  }

  &__ribbon-underline {
    position: absolute;
    bottom: 25%;
    left: 24.8%;
    width: 50%;
  }

  &__ribbon-flag {
    position: absolute;
    top: -30%;
    left: 9%;
    width: 54%;
  }

  @media only screen and (max-width: 500px) {
    width: 87vw;
    padding: 1rem;

    &__button {
      height: 283px;
    }

    &__change-reading-level {
      padding: 0rem 0rem;
    }
    &__stripe-h2 {
      margin-top: 123px;
      margin-bottom: 0px;
    }
    &__premium-card {
      padding: 0.5rem 1rem;
      margin: 0rem;
      margin-bottom: 0.5rem;

      &:first-child {
        margin-right: 0.35rem;
        margin-left: -0.2rem;
      }

      &:last-child {
        margin-left: 0.35rem;
        margin-right: -0.2rem;
      }

      &--heading {
        font-size: 1.2rem;
        margin-top: 8%;
      }

      &--price {
        font-size: 1.7rem;
      }
    }

    &__ribbon-title {
      position: absolute;
      top: 10%;
      left: 7%;
      width: 90%;
    }

    &__ribbon-underline {
      position: absolute;
      bottom: 25%;
      left: 14.9%;
      width: 70%;
    }

    &__ribbon-flag {
      position: absolute;
      top: -21%;
      left: 9%;
      width: 54%;
    }
  }
}

@-webkit-keyframes wiggleSlow {
  0% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(-1deg);
  }
  10% {
    -webkit-transform: rotate(2deg);
  }
  15% {
    -webkit-transform: rotate(-0.5deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggleSlow {
  0% {
    -ms-transform: rotate(1deg);
  }
  5% {
    -ms-transform: rotate(-1deg);
  }
  10% {
    -ms-transform: rotate(2deg);
  }
  15% {
    -ms-transform: rotate(-0.5deg);
  }
  20% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggleSlow {
  0% {
    transform: rotate(1deg);
  }
  5% {
    transform: rotate(-1deg);
  }
  10% {
    transform: rotate(2deg);
  }
  15% {
    transform: rotate(-0.5deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
